import type { RouteComponentProps } from '@gatsbyjs/reach-router'
import React from 'react'

import { CasiSession } from './CasiSession'

export type CasiSessionRouteProps = RouteComponentProps<{ slug: string }>

export const CasiSessionRoute: React.FC<CasiSessionRouteProps> = ({ slug }) => {
  if (!slug) {
    return null
  }

  return <CasiSession slug={slug} />
}
