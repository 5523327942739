import { QueryHookOptions, QueryResult, TypedDocumentNode, useQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useContext } from 'react'

import { TokenContext } from '../layouts/context'

const emptyOptions = {}

export const useApiQuery = <TData = unknown, TVariables = Record<string, unknown>>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    skipWithoutToken,
    ...options
  }: QueryHookOptions<TData, TVariables> & { skipWithoutToken?: boolean } = emptyOptions,
): QueryResult<TData, TVariables> => {
  const accessToken = useContext(TokenContext)
  return useQuery(query, {
    ...options,
    context: {
      ...(options && options.context),
      uri: '/api/graphql',
      token: accessToken,
    },
    skip: !!options.skip || (skipWithoutToken && !accessToken),
  })
}
