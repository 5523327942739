// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useEffect } from 'react'

import { libraryUrl } from '../../../lib/library'
import { usePageUrl } from '../../../lib/pages'

export type CasiSessionRootRouteProps = RouteComponentProps<{ slug: string }>

// NOTE: used this method instead of a `<Redirect />` because we need to redirect outside of
// the `<Router />` base path
export const CasiSessionRootRoute: React.FC<CasiSessionRootRouteProps> = () => {
  const { navigate } = usePageUrl()
  useEffect(() => {
    navigate(libraryUrl)
  }, [navigate])
  return null
}
