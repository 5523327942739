import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Router } from '@gatsbyjs/reach-router'
import React from 'react'
import styled, { css } from 'styled-components'

import Head from '../../../layouts/Head'
import { getRouterBasePath, useDispensaryContext } from '../../../lib/dispensaries'
import { libraryUrl } from '../../../lib/library'
import Layout from '../../global/Layout'
import { CasiSessionRootRoute } from './CasiSessionRootRoute'
import { CasiSessionRoute } from './CasiSessionRoute'

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

export const CasiSessionPage: React.FC = () => (
  <>
    <Head title="CASI Session" />
    <GlobalStyle includeTypography />
    <Layout>
      <Container>
        <Router
          basepath={getRouterBasePath(`${libraryUrl}/casi-session`, !!useDispensaryContext())}
        >
          <CasiSessionRootRoute path="/" />
          <CasiSessionRoute path="/:slug" />
        </Router>
      </Container>
    </Layout>
  </>
)
