import { useLocation } from '@gatsbyjs/reach-router'
import { stringify } from 'query-string'
import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { useId } from 'react-id-generator'
import styled from 'styled-components'

import {
  isOnInstapageNavigateMessage,
  isOnResizeMessage,
  isSandboxCallbackMessage,
  OnInstapageNavigateMessage,
  thirdPartySandboxUrl,
} from '../../lib/third-party-sandbox'

export type ThirdPartySandboxQueryParams = Record<Exclude<string, 'id' | 'o'>, string | string[]>

type IFrameProps = React.ComponentPropsWithoutRef<'iframe'>

export type ThirdPartySandboxIFrameProps = Omit<
  IFrameProps,
  'sandbox' | 'src' | 'style' | 'title'
> &
  // require sandbox for security and title for best practices
  Required<Pick<IFrameProps, 'sandbox' | 'title'>> & {
    path: string
    params: ThirdPartySandboxQueryParams
  } & {
    onInstapageNavigateMessage?: (data: OnInstapageNavigateMessage['data']) => void
  }

const ThirdPartySandboxIFrame: React.VFC<ThirdPartySandboxIFrameProps> = ({
  params,
  path,
  title,
  onInstapageNavigateMessage,
  ...rest
}) => {
  const location = useLocation()
  const [iframeId] = useId(1, 'sandbox-iframe-')
  const [iframeStyle, setIframeStyle] = useState<CSSProperties>({ height: 0 })

  useEffect(() => {
    const messageListener = (ev: MessageEvent): void => {
      if (ev.origin !== thirdPartySandboxUrl) {
        return
      }
      const { data: message } = ev
      if (!isSandboxCallbackMessage(message) || message.id !== iframeId) {
        return
      }
      if (isOnResizeMessage(message)) {
        setIframeStyle({ height: message.data.height })
        return
      }
      if (isOnInstapageNavigateMessage(message)) {
        if (!onInstapageNavigateMessage) {
          console.warn(
            '[third-party-sandbox] onInstapageNavigateMessage callback not provided for event',
          )
          return
        }
        onInstapageNavigateMessage(message.data)
        return
      }
      console.warn('[third-party-sandbox] handler not provided for event', ev)
    }
    window.addEventListener('message', messageListener)
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [iframeId, onInstapageNavigateMessage])

  const iframeSrc = useMemo(
    () =>
      location?.origin
        ? `${thirdPartySandboxUrl}${path}?${stringify({
            ...params,
            id: iframeId,
            o: location.origin,
          })}`
        : undefined,
    [iframeId, location?.origin, params, path],
  )

  return iframeSrc ? (
    <iframe
      loading="lazy"
      scrolling="no"
      src={iframeSrc}
      style={iframeStyle}
      title={title}
      {...rest}
    />
  ) : null
}

const StyledThirdPartySandboxIFrame = styled(ThirdPartySandboxIFrame)`
  width: 100%;
  border: none;
  display: block;
  overflow: hidden;
`

export default StyledThirdPartySandboxIFrame
