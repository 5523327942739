import React, { FC, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { CasiSessionResourceDocument } from '../../../graphql/api'
import { useApiQuery } from '../../../hooks/useApiQuery'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { libraryUrl } from '../../../lib/library'
import ContactSupportMessage from '../../ContactSupportMessage'
import FullPageError from '../../FullPageError'
import Link from '../../global/Link'
import ThirdPartySandboxIFrame, {
  ThirdPartySandboxQueryParams,
} from '../../global/ThirdPartySandboxIFrame'
import Spinner from '../../Spinner'

import MagnifyingGlassSvg from '../../../img/magnifying-glass.inline.svg'

export interface CasiSessionProps {
  slug: string
}

const Header = styled.header`
  padding: 20px;
`

const MagnifyingGlass = styled(MagnifyingGlassSvg)`
  color: ${({ theme }) => theme.colors.brand.orange};
  display: inline-block;
  height: 2em;
  vertical-align: -0.7em;
`

export const CasiSession: FC<CasiSessionProps> = ({ slug }) => {
  const { called, loading, data, error } = useApiQuery(CasiSessionResourceDocument, {
    variables: { slug },
  })
  useLogAndCaptureError(error)

  const casiSession = data?.casiSessionResource

  // TODO: make configurable in Sanity
  const aspectRatio = '16:9'

  const params = useMemo(
    (): ThirdPartySandboxQueryParams | undefined =>
      casiSession?.wistiaShareUrl
        ? {
            r: aspectRatio,
            w: casiSession.wistiaShareUrl,
          }
        : undefined,
    [casiSession?.wistiaShareUrl],
  )

  if (!called || loading) {
    return (
      <div>
        <Helmet title="Loading CASI Session" />
        <Spinner loading />
      </div>
    )
  }

  if (error) {
    return (
      <FullPageError>
        <Helmet title="Error" />
        An error occurred retrieving data, and we have been notified.
        <small>
          <ContactSupportMessage />
        </small>
        <small>
          <Link to={libraryUrl}>Go back to Library</Link>
        </small>
      </FullPageError>
    )
  }

  if (!casiSession || !params) {
    return (
      <FullPageError>
        <Helmet title="CASI Session not found" />
        <MagnifyingGlass /> CASI Session Not Found
        <small>
          <Link to={libraryUrl}>Go back to Library</Link>
        </small>
      </FullPageError>
    )
  }

  return (
    <div>
      <Helmet title={casiSession.title || 'CASI Session'} />
      <Header>{casiSession.title && <h2>{casiSession.title}</h2>}</Header>
      <ThirdPartySandboxIFrame
        allowFullScreen
        params={params}
        path="/wistia/index.html"
        sandbox="allow-scripts allow-same-origin"
        title="CASI Session Video"
      />
    </div>
  )
}
